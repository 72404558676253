import React from "react"
import { Link } from "gatsby"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Accordion from "../components/accordion"

const Faqs = () => (
  <Layout>
    <SEO title="Preguntas Frecuentes" />
    <section className="container faq">
      <div className="columns">
        <div className="column is-8 is-offset-2">
          <h3 className="faq__title">
            <br /><br />
            <Fade delay={200}>Preguntas Frecuentes</Fade>
          </h3>
          <Fade bottom delay={200}>
            <Accordion>
              <div label="¿Cómo me puede ayudar BiT CRM a crecer mi desarrolladora?">
                <p className="panel">
                  <Fade>
                    Bit es un software bajo servicio (SaaS) desarrollado
                    específicamente para gestionar la parte comercial de todas
                    las desarrolladoras inmobiliarias, cuenta con un proceso
                    sistematizado que acompaña al asesor y gerentes desde la
                    prospección y captación de un prospecto hasta la firma del
                    contrato. Nuestros especialistas de BI (Business
                    Intelligence) han diseñado reportes y analíticas que
                    facilitarán la toma de decisiones para poder mejorar la
                    productividad de tus asesores.
                  </Fade>
                </p>
              </div>
              <div label="¿Mi desarrolladora es muy pequeña para utilizar BiT?">
                <p className="panel">
                  <Fade>
                    Te recomendamos utilizar nuestra herramienta en cualquier
                    proyecto de cualquier tamaño ya que una gestión comercial
                    correcta puede definir el éxito del mismo.
                  </Fade>
                </p>
              </div>
              <div label="¿Qué contiene una licencia SaaS?">
                <p className="panel">
                  <Fade>
                    SaaS es una licencia de pago por el uso que le des a la
                    aplicación, es por esto que BiT te ofrece en la licencia
                    básica lo siguiente:
                    <ul>
                      <li>Usuarios ilimitados</li>
                      <li>
                        Soporte Técnico ilimitado por nuestro chat y whatsapp
                      </li>
                      <li>Un Proyecto</li>
                      <li>Dos horas de capacitación vía remota al mes</li>
                      <li>
                        Integración a redes sociales como website y Facebook
                      </li>
                      <li>
                        Envío de 10,000 correos para campañas de marketing
                      </li>
                      <li>3,500 prospectos</li>
                    </ul>
                  </Fade>
                </p>
              </div>
              <div label="¿Tengo que contratarlo por un periodo largo de tiempo?">
                <p className="panel">
                  <Fade>
                    No, el sistema está hecho para ayudarte en hacer más
                    efectiva la parte comercial es por esto que solo contratas
                    mientras dure tu proyecto.
                  </Fade>
                </p>
              </div>
              <div label="¿Puedo realizar pagos semestrales o anuales?">
                <p className="panel">
                  <Fade>¿En cuántos proyectos puedo implementar BiT?</Fade>
                </p>
              </div>
              <div label="¿Puedo realizar pagos semestrales o anuales?">
                <p className="panel">
                  <Fade>
                    En todos los que gustes implementarlo, el sistema cuenta con
                    accesos restringidos por lo que tu decides a quién asignar a
                    cada proyecto y qué rol tendrán en este.
                  </Fade>
                </p>
              </div>
              <div label="¿Puedo acceder a BiT desde cualquier lugar?">
                <p className="panel">
                  <Fade>
                    Claro, el sistema está hecho para que sea accesible desde
                    cualquier dispositivo.
                  </Fade>
                </p>
              </div>
              <div label="¿Cómo es el soporte que ofrece BiT CRM?">
                <p className="panel">
                  <Fade>
                    Ilimitado por el chat que está insertado en la plataforma y
                    por whatsapp, nuestro equipo se encuentra en la mejor
                    disposición en ayudarte para eficientar tus procesos.
                  </Fade>
                </p>
              </div>
              <div label="¿Hay algún cargo por la configuración de la información?">
                <p className="panel">
                  <Fade>
                    No, tú puedes cargar toda la información y modificarla sin
                    ningún problema. BiT te ofrece éste servicio por si deseas
                    que te entreguemos “llave en mano” y comiences a cotizar y
                    vender cuanto antes.
                  </Fade>
                </p>
              </div>
              <div label="¿Cuánto tiempo tardan en la configuración de la información?">
                <p className="panel">
                  <Fade>Aproximadamente 15 días.</Fade>
                </p>
              </div>
              <div label="¿Qué tan segura se encuentra mi información?">
                <p className="panel">
                  <Fade>
                    Totalmente segura, nuestros servidores cuentan con
                    certificados de seguridad y también tenemos respaldos
                    diarios de la información.
                  </Fade>
                </p>
              </div>
              <div label="¿Se integra con mi website y redes sociales?">
                <p className="panel">
                  <Fade>
                    Claro, Una vez que un prospecto deja sus datos en tu página
                    web o formas de contacto de facebook se registra en el
                    sistema automáticamente y se le asigna a tu gerente de
                    ventas para que él pueda asignar este prospecto al asesor
                    que desee.
                  </Fade>
                </p>
              </div>
              <div label="¿Puedo exportar mi información?">
                <p className="panel">
                  <Fade>
                    Bit te ofrece que puedas exportar cuando desees toda tu base
                    de datos y tus ventas.
                  </Fade>
                </p>
              </div>
              <div label="¿Cuál es la política de cancelaciones?">
                <p className="panel">
                  <Fade>
                    El servicio lo puedes cancelar dentro de los primeros cinco
                    días de cada mes.
                  </Fade>
                </p>
              </div>
            </Accordion>
          </Fade>
        </div>
      </div>
    </section>

    <section className="hero is-medium">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="columns">
            <div className="column is-6 is-offset-3">
              <h1 className="title land-about__title">
                ¿Tienes alguna otra duda?
              </h1>
              <Link className="button btn-main" to="/soporte">
                <strong>Contáctanos</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Faqs
