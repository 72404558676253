import React, { Component } from "react"
import PropTypes from "prop-types"

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    this.props.onClick(this.props.label)
  }

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this

    return (
      <div>
        <div
          onClick={onClick}
          style={{ cursor: "pointer" }}
          className="accordionTitle"
        >
          <p className="accordion">{label}</p>
          <div className="accordionIcon">
            {!isOpen && <span className="active">+</span>}
            {isOpen && <span className="active">-</span>}
          </div>
        </div>
        {isOpen && <div>{this.props.children}</div>}
      </div>
    )
  }
}

export default AccordionSection
